














































































































































































































































































































































































































@import "../../assets/css/table";
.uploadImg {
    width: 138px;
    height: 42px;
    background: #F0F2F8;
    border: none;
    color: #999DAF;

    i {
        margin-right: 5px;
    }
}
